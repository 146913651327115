/* tables */
/*table.tablesorter {
	font-family:arial;
	background-color: #CDCDCD;
	margin:10px 0pt 15px;
	font-size: 8pt;
	width: 100%;
	text-align: left;
}
table.tablesorter thead tr th, table.tablesorter tfoot tr th {
	background-color: #e6EEEE;
	border: 1px solid #FFF;
	font-size: 8pt;
	padding: 4px;
}
table.tablesorter thead tr .header {
	background-image: url(bg.gif) !important;
	background-repeat: no-repeat;
	background-position: center right;
	cursor: pointer;
}
table.tablesorter tbody td {
	color: #3D3D3D;
	padding: 4px;
	background-color: #FFF;
	vertical-align: top;
}
table.tablesorter tbody tr.odd td {
	background-color:#F0F0F6;
}
table.tablesorter thead tr .headerSortUp {
	background-image: url(asc.gif) !important;
}
table.tablesorter thead tr .headerSortDown {
	background-image: url(desc.gif) !important;
}
table.tablesorter thead tr .headerSortDown, table.tablesorter thead tr .headerSortUp {
background-color: #8dbdd8;
}
*/
table.tablesorted th.header.headerSortUp{
	background-image: url(asc.gif);
	background-repeat: no-repeat;
    background-position: center right;
}

table.tablesorted th.header.headerSortDown{
	background-image: url(desc.gif);
	background-repeat: no-repeat;
    background-position: center right;
}

table.tablesorted th.header{
	background-image: url(bg.gif);
	background-repeat: no-repeat;
    background-position: center right;
}

table.tablesorted th.header.no-sort{
	background-image: none;
}

